import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  AppBar,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { useAuth0 } from "../../utils/auth0Provider";

import {
  Click_click_click_subagreement,
  Click_click_click_tracking,
  Click_click_click_transactions
} from "../../generated/Click";

import ClickShowListItem from "../ClickShowListItem";

import ConversionForm from "../ConversionForm";
import ClickForm from "../ClickForm";

interface IClickShowProps {
  id: string;
  destination: string;
  clickedAt: string;
  playerId: string | null;
  s1: string | null;
  s2: string | null;
  s3: string | null;
  s4: string | null;
  s5: string | null;
  subagreement: Click_click_click_subagreement;
  tracking: Click_click_click_tracking;
  trackReferer: string | null;
  trackIp: string | null;
  trackCurrentUrl: string | null;
  trackUserAgent: string | null;
  transactions: Click_click_click_transactions[];
}

const ClickShow = ({
  id,
  destination,
  clickedAt,
  playerId,
  s1,
  s2,
  s3,
  s4,
  s5,
  subagreement,
  tracking,
  trackReferer,
  trackIp,
  trackCurrentUrl,
  trackUserAgent,
  transactions
}: IClickShowProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { hasPermission } = useAuth0();
  const createTransactions = hasPermission("manage:clicks");

  const [tabIndex, setTabIndex] = useState(0);
  const [addConversionOpen, setAddConversionOpen] = useState(false);
  const [updateClickOpen, setUpdateClickOpen] = useState(false);
  const [showRemoved, setShowRemoved] = useState(false);

  const filteredTransactions = showRemoved
    ? transactions
    : transactions.filter(transaction => !transaction.removed);

  const onAddConversionClose = () => {
    setAddConversionOpen(false);
  };
  
  const onUpdateClickClose = () => {
    setUpdateClickOpen(false);
  };

  return (
    <Paper className={classes.paper}>
      <Typography
        className={classes.backToSearch}
        onClick={() => history.push("/tech/conversion-management")}
        variant="subtitle1"
      >{`< Look up another click id`}</Typography>
      <div className={classes.header}>
        <Typography variant="h5">{`Click #${id}`}</Typography>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            onClick={() => setUpdateClickOpen(true)}
            variant="contained"
          >
            Edit
          </Button>
          {createTransactions && (
            <Button
              color="primary"
              onClick={() => setAddConversionOpen(true)}
              variant="contained"
            >
              <AddIcon className={classes.buttonIcon} />
              Conversion
            </Button>
          )}
        </div>
      </div>

      <AppBar
        className={classes.appBar}
        color="default"
        elevation={0}
        position="static"
      >
        <Tabs
          onChange={(_, value: number) => setTabIndex(value)}
          value={tabIndex}
        >
          <Tab label="Details" />
          <Tab label="Conversions" />
        </Tabs>
      </AppBar>

      <div hidden={tabIndex !== 0}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell>Click Date</TableCell>
              <TableCell>{new Date(clickedAt).toUTCString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Player ID</TableCell>
              <TableCell>{playerId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Advertiser</TableCell>
              <TableCell>
                {tracking.creative.offer.brand.advertiser.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Brand</TableCell>
              <TableCell>{tracking.creative.offer.brand.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Originating Offer</TableCell>
              <TableCell>{tracking.creative.offer.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Current Offer</TableCell>
              <TableCell>{subagreement.offer.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Creative</TableCell>
              <TableCell>{tracking.creative.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Partner</TableCell>
              <TableCell>{tracking.subagreement.user.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Referer</TableCell>
              <TableCell>{trackReferer}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>S1</TableCell>
              <TableCell>{s1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>S2</TableCell>
              <TableCell>{s2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>S3</TableCell>
              <TableCell>{s3}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>S4</TableCell>
              <TableCell>{s4}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>S5</TableCell>
              <TableCell>{s5}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tracker</TableCell>
              <TableCell>{trackCurrentUrl}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Destination</TableCell>
              <TableCell>{destination}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>IP Address</TableCell>
              <TableCell>{trackIp}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>User Agent</TableCell>
              <TableCell>{trackUserAgent}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div hidden={tabIndex !== 1}>
        <div className={classes.toggleContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showRemoved}
                onChange={() => setShowRemoved(!showRemoved)}
                color="primary"
              />
            }
            label="Show Removed Conversions"
            labelPlacement="start"
          />
        </div>
        <TableContainer>
          {transactions.length ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Removed</TableCell>
                  <TableCell>Updated At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTransactions.map(transaction => (
                  <ClickShowListItem
                    id={transaction.id}
                    key={transaction.id}
                    currency={transaction.currency}
                    transactionType={transaction.transactionType}
                    transactionAt={transaction.transactionAt}
                    source={transaction.source}
                    amount={transaction.amount}
                    externalId={transaction.externalId}
                    removed={transaction.removed}
                    updatedAt={transaction.updatedAt}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography className={classes.noConversions}>
              This click has never converted
            </Typography>
          )}
        </TableContainer>
      </div>

      <ClickForm
        clickId={id}
        onClose={onUpdateClickClose}
        open={updateClickOpen}
        currentOfferId={subagreement.offer.id}
        currentOfferName={subagreement.offer.name}
        currentPlayerId={playerId}
        currentSubagreementId={subagreement.id}
        userId={subagreement.user.id}
      />

      <ConversionForm
        clickId={id}
        onClose={onAddConversionClose}
        open={addConversionOpen}
        playerId={playerId}
      />
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  appBar: {
    display: "flex"
  },
  backToSearch: {
    "&:hover": {
      textDecoration: "underline"
    },
    cursor: "pointer",
    padding: spacing(1, 1, 1, 3),
    width: "fit-content"
  },
  buttonContainer: {
    "& > *": {
      margin: spacing(1)
    }
  },
  buttonIcon: {
    marginRight: spacing()
  },
  conversionsTableHeader: {
    paddingLeft: spacing()
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: spacing(0, 3, 2, 3)
  },
  noConversions: {
    padding: spacing(2, 2, 2, 1)
  },
  paper: {
    paddingBottom: spacing(3)
  },
  table: {
    marginLeft: spacing(3),
    width: "auto"
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: spacing(3)
  }
}));

export default ClickShow;
