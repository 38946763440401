import { gql } from "@apollo/client";

const UserSubagreementsSearchQuery = gql`
  query UserSubagreementsSearch(
    $search: String
    $status: [SubagreementStatus!]
    $userId: ID!
  ) {
    subagreements(
      search: $search
      status: $status
      userId: $userId
    ) {
      edges {
        cursor
        node {
          id
          offer {
            id
            name
          }
        }
      }
    }
  }
`;

export default UserSubagreementsSearchQuery;
